import axios from '@/plugins/axios'
import { getAccountId } from '@/helper/auth-helper'

const getDetailAccount = () => axios.get(`${getAccountId()}/settings/account-details`)
const updateDetailAccount = formData => axios.patch(`${getAccountId()}/settings/account-details`, formData)

const getPaymentHistory = params => axios.get(`${getAccountId()}/settings/payments`, { params })

const getLoginSecurity = params => axios.get(`${getAccountId()}/settings/login-security`, { params })

const uploadVerificationFiles = ({ formData, config }) => axios.post(`${getAccountId()}/settings/documents`, formData, config)

const deleteAccount = formData => axios.post(`${getAccountId()}/settings/account-details`, formData)

export {
  getDetailAccount, updateDetailAccount, getPaymentHistory, getLoginSecurity, uploadVerificationFiles, deleteAccount
}