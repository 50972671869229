<template>
  <v-dialog
    v-model="show"
    v-bind="$attrs"
    width="500px"
    persistent
    overlay-color="overlayColor"
  >
    <div class="d-flex justify-center align-content-center">
      <iframe v-if="showIframe" id="stripe-iframe" :src="url"></iframe>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogStripe3D',
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      default: ''
    },
  },
  data: () => ({
    showIframe: false,
  }),
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
  mounted() {
  
  },
  methods: {
    openIframe() {
      this.$nextTick(() => {
        this.showIframe = true
        this.setListener()
      })
    },
    closeIframe() {
      this.removeListeners()
      this.showIframe = false
    },
    setListener() {
      window.addEventListener('message', payload => {
        if (payload.data.hasOwnProperty('status')) {
          if (!!payload.data.status) {
            this.$router.push({ name: 'PaymentHistory' })
          }
          
          this.show = false
        }
      }, false)
    },
    removeListeners() {
      window.removeEventListener('message', () => {}, false)
    }
  },
  watch: {
    show(value) {
      if (value) {
        this.openIframe()
      } else {
        this.closeIframe()
      }
    },
  },
}
</script>

<style lang="scss">
  #stripe-iframe {
    border: none;
    width: 500px;
    height: 600px;
  }
</style>
