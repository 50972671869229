<template>
  <v-container fluid class="pt-8 px-8 pa-sm-15">
    <div class="header-block d-flex flex-wrap align-center justify-space-between pb-sm-15">
      <div class="d-flex align-center pb-8">
        <portal to="pageTitle" :disabled="checkMd">
          <div class="font-page-header pr-5 pr-sm-15">fill balance</div>
        </portal>
  
        <ActionButton class="mr-5 mr-sm-15" :to="{ name: 'ManageCards' }">Manage Cards</ActionButton>
  
        <ActionButton @click="closeAction">Payment History</ActionButton>
      </div>
      
      <div class="pb-8">
        <CancelButton @click="closeAction">Close</CancelButton>
      </div>
    </div>
    
    <CardFillBalance/>
    
    <CardAutoRecharge/>
  </v-container>
</template>

<script>
import CardFillBalance from '@/components/cards/CardFillBalance'
import CardAutoRecharge from '@/components/cards/CardAutoRecharge'
import ActionButton from '@/components/buttons/ActionButton'
import CancelButton from '@/components/buttons/CancelButton'

export default {
  name: 'FillBalance',
  components: { CardFillBalance, CardAutoRecharge, ActionButton, CancelButton },
  computed: {
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
  },
  methods: {
    closeAction() {
      this.$router.push({ name: 'PaymentHistory' })
    },
  }
}
</script>
