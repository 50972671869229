<template>
  <v-card
    class="create-card cardBg py-15 px-12 rounded-20 mt-8 mt-sm-15"
    :class="checkSm ? 'py-sp-23 px-sp-20' : 'py-sm-25 px-sm-25'"
    flat
    outlined
  >
    <v-row class="setup-content d-block d-md-flex align-center" no-gutters>
      <v-col cols="12" md="4" lg="2">
        <TextDescription :description="description[0]" />
      </v-col>
      
      <v-col cols="12" md="8" lg="10">
        <v-form ref="form" v-model="validateForm">
          <v-row no-gutters>
            <v-col
              class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
              cols="12" sm="4" md="3"
            >
              <span class="font-normal nameField--text text-sm-right">Auto recharge</span>
            </v-col>
            <v-col class="pb-10" cols="12" sm="8" md="9">
              <ToggleButton v-model="option" :buttons="autoRechargeOptions"/>
            </v-col>
            
            <v-col
              class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
              cols="12" sm="5" md="3"
            >
              <span class="font-normal nameField--text">Balance Amount, $</span>
              <portal-target
                class="field-message font-normal error--text text-sm-right"
                name="minBalanceAmount"
              ></portal-target>
            </v-col>
            <v-col class="pb-10" cols="12" sm="7" md="9">
              <FormField
                v-model="formData['min_balance_amount']"
                :rules="rules"
                :loading="loading"
                class="field-container"
                type="number"
                :disabled="!option"
              >
                <template #message="{ key, message }">
                  <portal to="minBalanceAmount" v-if="!!message">
                    {{ message }}
                  </portal>
                </template>
              </FormField>
            </v-col>
            
            <v-col
              class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
              cols="12" sm="5" md="3"
            >
              <span class="font-normal nameField--text">Recharge Amount, $</span>
              <portal-target
                class="field-message font-normal error--text text-sm-right"
                name="rechargeAmount"
              ></portal-target>
            </v-col>
            <v-col class="pb-10" cols="12" sm="7" md="9">
              <FormField
                v-model="formData['recharge_amount']"
                :rules="rules"
                :loading="loading"
                class="field-container"
                type="number"
                :disabled="!option"
              >
                <template #message="{ key, message }">
                  <portal to="rechargeAmount" v-if="!!message">
                    {{ message }}
                  </portal>
                </template>
              </FormField>
            </v-col>
            
            <v-col cols="12" sm="7" md="9" offset="0" offset-sm="5" offset-md="3" class="mt-2 mt-sm-8">
              <ActionButton type="submit" @click.prevent="submitForm" :loading="loadingAction">Save settings</ActionButton>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import ActionButton from '@/components/buttons/ActionButton'
import ToggleButton from '@/components/buttons/ToggleButton'
import TextDescription from '@/components/description/TextDescription'
import FormField from '@/components/inputs/FormField'
import AutocompleteField from '@/components/select/AutocompleteField'

import { getDetailAccount } from '@/api/setting-routes'
import { autoRecharge } from '@/api/payment-routes'

export default {
  name: 'CardAutoRecharge',
  inheritAttrs: false,
  components: { ActionButton, ToggleButton, TextDescription, FormField, AutocompleteField },
  data: () => ({
    autoRechargeOptions: [
      { id: 0, name: 'Disabled' },
      { id: 1, name: 'Enabled' },
    ],
    option: 0,
    rules: [],
    loading: false,
    loadingAction: false,
    validateForm: false,
    description: [
      {
        title: 'Auto recharge',
        text: [
          'Enable auto recharge on your card',
          'to never run low on account credit.',
          'When your account balance is low,',
          'we will recharge with the amount',
          'specified by you.'
        ]
      },
    ],
    formData: {
      'min_balance_amount': null,
      'recharge_amount': null,
    },
    cards: [],
  }),
  computed: {
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
  },
  mounted() {
    this.loadDetailsAccount()
  },
  methods: {
    async loadDetailsAccount() {
      this.loading = true

      const { success, payload, message } = await getDetailAccount()

      if (success) {
        this.parseSettings(payload)
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    parseSettings(settings) {
      if (!!settings) {
        Object.keys(this.formData).forEach(name => {
          this.formData[name] = settings[name]
        })
      }
      
      if (!!this.formData['min_balance_amount'] && !!this.formData['recharge_amount']) {
        this.option = 1
      }
    },
    async submitForm() {
      if (!!this.option) {
        await this.validationForm()
      }
      
      if (!this.option || this.$refs.form.validate()) {
        this.loadingAction = true

        const { success, message } = await autoRecharge(this.formData)

        if (success) {
          
          this.$notify({ type: 'success-bg', text: message })
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }

        this.loadingAction = false
      }
    },
    validationForm() {
      this.rules = [
        v => !!v || 'This field is required'
      ]
    },
  },
  watch: {
    option: {
      handler(option) {
        if (!option) {
          this.rules = []
          this.formData['min_balance_amount'] = null
          this.formData['recharge_amount'] = null
        }
      }
    }
  }
}
</script>
