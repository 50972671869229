<template>
  <v-card
    class="create-card cardBg py-15 px-12 rounded-20"
    :class="checkSm ? 'py-sp-23 px-sp-20' : 'py-sm-25 px-sm-25'"
    flat
    outlined
  >
    <v-row class="setup-content d-block d-md-flex align-center" no-gutters>
      <v-col cols="12" md="4" lg="2">
        <TextDescription :description="description[0]" />
      </v-col>
      
      <v-col cols="12" md="8" lg="10">
        <v-form ref="form" v-model="validateForm">
          <v-row no-gutters>
<!--            <v-col-->
<!--              class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"-->
<!--              cols="12" sm="4" md="3"-->
<!--            >-->
<!--              <span class="font-normal nameField&#45;&#45;text text-sm-right">Choose an Option</span>-->
<!--            </v-col>-->
<!--            <v-col class="pb-10" cols="12" sm="8" md="9">-->
<!--              <ToggleButton v-model="type" :buttons="paymentTypes"/>-->
<!--            </v-col>-->
<!--            -->
<!--            -->
<!--            <v-expand-transition>-->
<!--              <v-col cols="12" v-show="!type">-->
<!--                <v-row no-gutters>-->
<!--                  <v-col-->
<!--                    class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"-->
<!--                    cols="12" sm="5" md="3"-->
<!--                  >-->
<!--                    <span class="font-normal nameField&#45;&#45;text">Card</span>-->
<!--                  </v-col>-->
<!--                  -->
<!--                  <v-col class="pb-10" cols="12" sm="7" md="9">-->
<!--                    <input type="hidden" name="payment_method" class="payment-method">-->
<!--                    <div class="credit-card-field p-relative">-->
<!--                      <div id="card-element"></div>-->

<!--                      <v-progress-linear-->
<!--                        :active="stripeLoading"-->
<!--                        :indeterminate="stripeLoading"-->
<!--                        absolute-->
<!--                        bottom-->
<!--                        height="2"-->
<!--                        color="primary"-->
<!--                      ></v-progress-linear>-->
<!--                    </div>-->
<!--                  </v-col>-->
<!--                </v-row>-->
<!--              </v-col>-->
<!--            </v-expand-transition>-->
            
<!--            <v-expand-transition>-->
              <v-col cols="12">
<!--                v-if="type"-->
                <v-row no-gutters>
                  <v-col
                    class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
                    cols="12" sm="5" md="3"
                  >
                    <span class="font-normal nameField--text">Saved cards</span>
                    <portal-target
                      class="field-message font-normal error--text text-sm-right"
                      name="savedCard"
                    ></portal-target>
                  </v-col>
                  
                  <v-col class="pb-10" cols="12" sm="7" md="9">
                    <AutocompleteField
                      class="field-container"
                      v-model="changedCard"
                      :items="cards"
                      item-text="name"
                      item-value="id"
                      :rules="rulesForCard"
                      checkBg
                      return-object
                    >
                      <template #message="{ key, message }">
                        <portal to="savedCard" v-if="!!message">
                          {{ message }}
                        </portal>
                      </template>

                      <template #item="{ item }">
                        <div class="v-list-item__content">
                          <div class="v-list-item__title" :class="{ 'error--text': !item.status }">
                            {{ item.name }}
                          </div>
                        </div>
                      </template>
                    </AutocompleteField>
                  </v-col>
                </v-row>
              </v-col>
<!--            </v-expand-transition>-->
            
            <v-col
              class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
              cols="12" sm="5" md="3"
            >
              <span class="font-normal nameField--text">Amount, $</span>
              <portal-target
                class="field-message font-normal error--text text-sm-right"
                name="vendorName"
              ></portal-target>
            </v-col>
            <v-col class="pb-10" cols="12" sm="7" md="9">
              <FormField
                v-model="amount"
                :rules="amountRules"
                :loading="loading"
                class="field-container"
                type="number"
              >
                <template #message="{ key, message }">
                  <portal to="vendorName" v-if="!!message">
                    {{ message }}
                  </portal>
                </template>
              </FormField>
            </v-col>
            
            <v-col cols="12" sm="7" md="9" offset="0" offset-sm="5" offset-md="3" class="mt-2 mt-sm-8">
              <ActionButton type="submit" @click.prevent="submitForm" :loading="loadingAction">Fill balance</ActionButton>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    
    <DialogStripe3D
      v-model="visibleStripeIframe"
      :url="stripeUrl"
    />
  </v-card>
</template>

<script>
import ActionButton from '@/components/buttons/ActionButton'
import ToggleButton from '@/components/buttons/ToggleButton'
import TextDescription from '@/components/description/TextDescription'
import FormField from '@/components/inputs/FormField'
import AutocompleteField from '@/components/select/AutocompleteField'
import SwitchLg from '@/components/controls/SwitchLg'
import DialogStripe3D from '@/components/dialog/DialogStripe3D'

import { billingProcess, getBillingMethods, getBillingPrepare } from '@/api/payment-routes'

import { loadStripe } from '@stripe/stripe-js/pure'

export default {
  name: 'CardFillBalance',
  inheritAttrs: false,
  components: { ActionButton, ToggleButton, TextDescription, FormField, AutocompleteField, SwitchLg, DialogStripe3D },
  data: () => ({
    // paymentTypes: [
    //   { id: 0, name: 'New card' },
    //   { id: 1, name: 'Saved cards' },
    // ],
    // type: 0,
    rules: [],
    amountRules: [],
    rulesForCard: [],
    changedCard: null,
    pulishableKey: null,
    token: null,
    card: null,
    amount: 100,
    loading: false,
    stripeLoading: true,
    validateForm: false,
    description: [
      {
        title: 'Fill your balance',
        text: []
      },
    ],
    loadingAction: false,
    cards: [],
    visibleStripeIframe: false,
    stripeUrl: null,
    stripeElement: null,
  }),
  computed: {
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
    stripe() {
      return loadStripe(this.pulishableKey, { locale: 'en' })
    },
    themeIsDark() {
      return this.$vuetify.theme.isDark
    },
    stripeStyle() {
      return {
        base: {
          color: this.themeIsDark ? '#dadada' : '#6c6c6c',
          fontFamily: '"Fira Sans", sans-serif',
          fontSize: '14px',
          '::placeholder': {
            color: '#A0A0A0'
          }
        },
        invalid: {
          color: '#F06269',
          iconColor: '#F06269'
        },
      }
    },
  },
  mounted() {
    this.loadBillingMethods()
    // this.loadBillingPrepare()
  },
  methods: {
    async loadBillingMethods() {
      const { success, payload, message } = await getBillingMethods()

      if (success) {
        this.parseCards(payload)

      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    parseCards(cards = []) {
      if (!cards.length) {
        return
      }

      this.cards = cards.map(({ id, card, metadata }) => ({
        name: `${card.brand} - **** ${card.last4}`,
        id: id,
        status: Array.isArray(metadata) || metadata.validated === 'true',
      }))

      // this.cards = [
      //   {
      //     name: 'visa - **** 3220',
      //     id: 'pm_1KZaavJmPUxegtt4LaV6cHF7',
      //     status: false,
      //   }
      // ]
    },
    async loadBillingPrepare() {
      const { success, payload, message } = await getBillingPrepare()

      if (success) {
        this.token = payload.intent['client_secret']
        this.pulishableKey = `${payload.key}`
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    async createStripeElement() {
      this.stripeElement = await this.stripe
      this.stripeLoading = false
      const elements = this.stripeElement.elements({
        fonts: [{
          cssSrc: 'https://fonts.googleapis.com/css2?family=Fira+Sans:wght@500;700&family=Poppins:wght@600&display=swap',
        }],
      })

      this.card = elements.create('card', { style: this.stripeStyle, hidePostalCode: true })
      this.card.mount('#card-element')
    },
    async setBillingProcess(formData) {
      const { success, redirect, payload, message } = await billingProcess(formData)
      
      if (redirect) {
        this.openDialog3DS(payload)
      } else if (success) {
        message && this.$notify({ type: 'success-bg', text: message })
        this.closeAction()
      } else {
        this.loadingAction = false
        message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    openDialog3DS(payload) {
      this.stripeUrl = payload
      this.visibleStripeIframe = true
    },
    // async checkPaymentCard() {
    //   try {
    //     const data = await this.stripeElement.confirmCardSetup(
    //       this.token,
    //       {
    //         payment_method: {
    //           card: this.card,
    //         }
    //       }
    //     )
    //
    //     if (data?.error) {
    //       this.resetStripeForm()
    //       this.$notify({ type: 'error-bg', duration: 15000, text: data.error.message })
    //       this.loadingAction = false
    //     } else {
    //       this.setBillingProcess({
    //         'payment_method': data.setupIntent['payment_method'],
    //         'value': this.amount,
    //       })
    //     }
    //   } catch (e) {
    //     this.$notify({ type: 'error-bg', duration: 15000, text: e.error.message })
    //     this.loadingAction = false
    //   }
    // },
    closeAction() {
      this.$router.push({ name: 'PaymentHistory' })
    },
    async submitForm() {
      await this.validationForm()
      if (this.$refs.form.validate()) {
        this.loadingAction = true

        // if (this.type) {
          this.setBillingProcess({
            'payment_method': this.changedCard.id,
            'value': this.amount,
          })
        // } else {
        //   this.checkPaymentCard()
        // }
      }
    },
    validationForm() {
      this.rules = [
        v => !!v || 'This field is required'
      ]
      this.rulesForCard = [
        v => !!v || 'This field is required',
        v => !!v && v.status || 'Card must be verified'
      ]
      this.amountRules = [
        v => !!v || 'This field is required',
        v => !!v && +v >= 25 || 'Minimum amount $25'
      ]
    },
    // resetStripeForm() {
    //   this.card.clear()
      // this.createStripeElement()
    // },
  },
  watch: {
    pulishableKey: {
      handler(key) {
        this.createStripeElement()
      }
    },
    // themeIsDark: {
    //   handler() {
    //     this.resetStripeForm()
    //   }
    // },
    visibleStripeIframe: {
      handler(value) {
        if (!value) {
          // this.resetStripeForm()
          this.loadingAction = false
        }
      }
    }
  },
  beforeDestroy() {
    if (this.card?.destroy) {
      this.card.destroy()
    }
  },
}
</script>
